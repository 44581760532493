import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw, RouterView  } from 'vue-router';
//import Home from '../views/Home.vue';
import Tr from "@/i18n/translation";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:locale?",
    component: RouterView,
    // beforeEnter: Tr.routeMiddleware,
    children: [
      {
        path: 'app',
        name: 'Home',
        //component: Home,
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
      },
      {
        path: '',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
      },
      {
        path: 'restrictionat',
        name: 'AccesBlocat',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AccesBlocat.vue'),
      },
      {
        path: 'documentator',
        name: 'Documentator',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Documentator.vue'),
      },
      {
        path: 'login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Login/Login.vue'),
      },
      {
        path: '',
        name: 'PaginaInLucru',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PaginaInLucru.vue'),
      },
      {
        path: 'status_numere',
        name: 'StatusNumere',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/StatusNumere.vue'),
      },
      {
        path: 'search',
        name: 'Search',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Search/Search.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'campanii_email',
        name: 'CampaniiEmail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/CampaniiEmail/CampaniiEmail.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'campanii_email',
        name: 'CampaniiEmail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/CampaniiEmail/CampaniiEmail.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'campanii_email/editmail/:campanieId',
        name: 'CampaniiEditMail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/CampaniiEmail/EditMail.vue'),
        props:true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'hrms/positions',
        name: 'HRMSPositions',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/HRMS/Positions/Positions/Positions.vue'),
        props:true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'hrms/organigrama',
        name: 'HRMSOrganigrama',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/HRMS/Organigrama/Organigrama.vue'),
        props:true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'hrms/personal',
        name: 'HRMSPersonal',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/HRMS/Personal/Personal.vue'),
        props:true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'hrms/position/:positionId',
        name: 'HRMSPosition',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/HRMS/Positions/Position/Position.vue'),
        props:true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'hrms/performance/people',
        name: 'HRMSPerformancePeople',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/HRMS/PerformanceTrack/People/People.vue'),
        props:true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'hrms/performance/person/:personId',
        name: 'HRMSPerformancePerson',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/HRMS/PerformanceTrack/Person/Person.vue'),
        props:true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports',
        name: 'Reports',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Reports/Reports.vue'),
        meta: {
          requiresAuth: true
        }
      },  
      {
        path: 'dashboard/:param',
        props: (route) => {      
          const param = route.params.param      
          return { param }
        },
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/Dashboard.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'document_nefinalizat',
        name: 'DocumentNefinalizat',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/DocumentNefinalizat/DocumentNefinalizat.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'de_repartizat',
        name: 'DeRepartizat',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/DeRepartizat/DeRepartizat.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'second_step_intrare/:appid',
        props: (route) => {      
          const appid = Number(route.params.appid)
          if (Number.isNaN(appid)) {
            return 0
          }
          return { appid }
        },
        name: 'SecondStepDocumentIntrare',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/SecondStepDocumentIntrare/SecondStepDocumentIntrare.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'view_document/:appid',
        props: (route) => {      
          const appid = Number(route.params.appid)
          if (Number.isNaN(appid)) {
            return 0
          }
          return { appid }
        },
        name: 'ViewDocument',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ViewDocument/ViewDocument.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'intrare',
        name: 'Intrare',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Intrare/Intrare.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'iesire',
        name: 'Iesire',    
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Iesire/Iesire.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'iesire_din_document',
        name: 'IesireDocument',    
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Iesire/Iesire.vue'),
        meta: {
          requiresAuth: true
        }
      },  
      {
        path: 'intern',
        name: 'Intern',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Intern/Intern.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'assets/:param',
        props: (route) => {  
          const param = route.params.param
          // const userid = Number(route.params.param)
          // if (Number.isNaN(userid)) {
          //   return 0
          // }
          // return { userid }
          return { param }
        },
        name: 'Assets',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Asset/Assets.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'edit_asset/:is_popupdialog/:param',
        props: (route) => {      
          const is_popupdialog = Number(route.params.is_popupdialog)
          const param = route.params.param

          if (Number.isNaN(is_popupdialog)) {
            return 0
          }
          return { is_popupdialog, param }
        },
        name: 'EditAsset',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Asset/EditAsset.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'assetsNotificationsList/:is_all',
        props: (route) => {      
          const is_all = Number(route.params.is_all)
          if (Number.isNaN(is_all)) {
            return 0
          }
          return { is_all }
        },
        name: 'AssetsNotificationsList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Asset/AssetsNotificationsList.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'second_step_asset/:appid',
        props: (route) => {      
          const appid = Number(route.params.appid)
          if (Number.isNaN(appid)) {
            return 0
          }
          return { appid }
        },
        name: 'WorkOnAsset',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Asset/WorkOnAsset.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {    
        path: 'mytimesrequests/:is_all',
        props: (route) => {      
          const is_all = Number(route.params.is_all)
          if (Number.isNaN(is_all)) {
            return 0
          }
          return { is_all }
        },
        name: 'MyTimesRequests',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/HR/MyTimesRequests/MyTimesRequests.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reportshr',
        name: 'ReportsHR',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/HR/ReportsHR/ReportsHR.vue'),
      },
      {
        path: 'adminprezenta/:is_all',
        props: (route) => {      
          const is_all = Number(route.params.is_all)
          if (Number.isNaN(is_all)) {
            return 0
          }
          return { is_all }
        },
        name: 'AdminPrezenta',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/HR/AdminPrezenta/AdminPrezenta.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'toate_repartizate',
        name: 'ToateRepartizate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ToateRepartizate/ToateRepartizate.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_categ',
        name: 'NomCateg',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomCateg/NomCateg.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_tip_doc',
        name: 'NomTipDoc',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomTipDoc/NomTipDoc.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_registre',
        name: 'NomRegistre',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomRegistre/NomRegistre.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_categ_active',
        name: 'NomCategActive',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomCategActive/NomCategActive.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_tip_active',
        name: 'NomTipActive',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomTipActive/NomTipActive.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_jobs',
        name: 'NomJobs',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomJobs/NomJobs.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_zi_libere_oficial',
        name: 'NomZiLibereOficial',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/HR/NomZiLibereOficial/NomZiLibereOficial.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'time_account',
        name: 'DrepturiCO',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/HR/TimeAccount/TimeAccounts.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'restrictii_concediu/:is_all',
        props: (route) => {      
          const is_all = Number(route.params.is_all)
          if (Number.isNaN(is_all)) {
            return 0
          }
          return { is_all }
        },
        name: 'RestrictiiConcediu',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/HR/RestrictiiConcediu/RestrictiiConcediu.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_entitate_acces',
        name: 'NomEntitateAcces',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomEntitateAcces/NomEntitateAccess.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_deplasari/:is_all',
        props: (route) => {      
          const is_all = Number(route.params.is_all)
          if (Number.isNaN(is_all)) {
            return 0
          }
          return { is_all }
        },
        name: 'NomDeplasari',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Deplasari/Deplasari.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_deconturi/:is_all',
        props: (route) => {      
          const is_all = Number(route.params.is_all)
          if (Number.isNaN(is_all)) {
            return 0
          }
          return { is_all }
        },
        name: 'NomDeconturi',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Deconturi/Deconturi.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_departamente',
        name: 'NomDepartamente',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomDepartamente.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_org_unit',
        name: 'NomOrgUnit',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomOrgUnit/NomOrgUnit.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_companies',
        name: 'NomCompanies',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomCompanies/NomCompanies.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_users',
        name: 'NomUsers',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomUsers/NomUsers.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'logadmin',
        name: 'LogAdmin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/LogAdmin/LogAdmin.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'params_scans',
        name: 'ParamsTypeFiles',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ParamsTypeFiles/ParamsTypeFiles.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'pontaje',
        name: 'Pontaje',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Pontaje/Pontaje.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'monitorizare',
        name: 'Monitorizare',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Pontaje/Monitorizare.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_cladiri_arhiva',
        name: 'NomCladiriArhiva',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomArhiva/NomCladiriArhiva.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_camere_arhiva',
        name: 'NomCamereArhiva',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomArhiva/NomCamereArhiva.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_dulapuri_arhiva',
        name: 'NomDulapuriArhiva',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomArhiva/NomDulapuriArhiva.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_rafturi_arhiva',
        name: 'NomRafturiArhiva',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomArhiva/NomRafturiArhiva.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'adrese',
        name: 'Adrese',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Adresa/Adrese.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'proiecte',
        name: 'Proiecte',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Proiect/Proiecte.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_flux',
        name: 'NomFlux',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NomFlux/NomFlux.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'referate/:is_all',
        props: (route) => {      
          const is_all = Number(route.params.is_all)
          if (Number.isNaN(is_all)) {
            return 0
          }
          return { is_all }
        },
        name: 'Referate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Referat/Referate.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'cereri/:is_all',
        props: (route) => {      
          const is_all = Number(route.params.is_all)
          if (Number.isNaN(is_all)) {
            return 0
          }
          return { is_all }
        },
        name: 'Cereri',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Cerere/Cereri.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'arhive/:is_all',
        props: (route) => {      
          const is_all = Number(route.params.is_all)
          if (Number.isNaN(is_all)) {
            return 0
          }
          return { is_all }
        },
        name: 'Arhive',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Arhive/Arhive.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'operatiuniraportpersonal',
        name: 'OperatiuniRaportPersonal',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Operatiuni/OperatiuniRaportPersonal.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'documentecetateni',
        name: 'DocumenteCetateni',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/DocumenteCetateni/DocumenteCetateni.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'tasks',
        name: 'MyTasks',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/Tasks/MyTasks.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'alocari_entitati_acces',
        name: 'AlocariEntitatiAcces',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AlocariEntitatiAcces/AlocareEntitateAcces.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'pontaj_prezenta/:is_all',
        props: (route) => {      
          const is_all = Number(route.params.is_all)
          if (Number.isNaN(is_all)) {
            return 0
          }
          return { is_all }
        },
        name: 'PontajPrezenta',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PontajPrezenta/PontajPrezenta.vue'),
        meta: {
          requiresAuth: true
        }
      }, 
      {
        path: 'pontaj_proiect/:is_all',
        props: (route) => {      
          const is_all = Number(route.params.is_all)
          if (Number.isNaN(is_all)) {
            return 0
          }
          return { is_all }
        },
        name: 'PontajProiect',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PontajProiect/PontajProiect.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'pontaj_task/:is_all',
        props: (route) => {      
          const is_all = Number(route.params.is_all)
          if (Number.isNaN(is_all)) {
            return 0
          }
          return { is_all }
        },
        name: 'PontajTask',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PontajTask/PontajTask.vue'),
        meta: {
          requiresAuth: true
        }
      }, 
      {
        path: 'change_password/:userid',
        props: (route) => {      
          const userid = Number(route.params.userid)
          if (Number.isNaN(userid)) {
            return 0
          }
          return { userid }
        },
        name: 'ChangePassword',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ChangePassword/ChangePassword.vue'),
        meta: {
          requiresAuth: true
        }    
      },
      {
        path: 'nom_judet',
        name: 'NomJudet',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Judet/Judete.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'nom_localitate',
        name: 'NomLocalitate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Localitate/Localitati.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'clienti',
        name: 'Clienti',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Client/Clienti.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'evenimente',
        name: 'Evenimente',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Eveniment/Evenimente.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'taskuri/:is_all',
        props: (route) => {      
          const is_all = Number(route.params.is_all)
          if (Number.isNaN(is_all)) {
            return 0
          }
          return { is_all }
        },
        name: 'Taskuri',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Proiect/MyProiecteTaskuri.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'api_extern_intergation/:param',
        props: (route) => {  
          const param = route.params.param
          // const userid = Number(route.params.param)
          // if (Number.isNaN(userid)) {
          //   return 0
          // }
          // return { userid }
          return { param }
        },
        name: 'ApiExternIntergation',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ApiExternIntergation/ApiExternIntergation.vue'),
        meta: {
          requiresAuth: true
        }    
      },
      {
        path: 'bianalytics',
        name: 'BIAnalytics',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/BIAnalytics/BIAnalytics.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'posturi',
        name: 'Posturi',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Post/Posturi.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'certificateurbanism',
        name: 'CertificateUrbanism',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Documente/CertificateUrbanism.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'serviciidetransport',
        name: 'ServiciiDeTransport',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Documente/ServiciiDeTransport.vue'),
        meta: {
          requiresAuth: true
        }
      },
    ]
  },
  { 
    path: '/:catchAll(.*)', // Unrecognized path automatically matches 404
    redirect: '/home',
    //path: '*', 
    //component: Home     
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), //createWebHashHistory(),//
  //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  Tr.routeMiddleware;

  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('statusAuth') == null || localStorage.getItem('statusAuth') != 'yes') {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
